html {
  @apply bg-gray-800;
  transform-origin: top left;
  height: 100%;
}
html.zoom-in {
  transition: transform 3s ease-in;
  transform: scale(2) translate(0%, -15%);
}
html.zoom-out {
  transition: transform 1s ease-in 1s;
}
.App {
  @apply bg-gray-800;
}
.App .wheel {
  clip-path: circle(50% at 50% 50%);
  transform: rotate(var(--last-rotation));
}
.App .wheel.spinning {
  transform: rotate(var(--rotation));
  transition: transform var(--rotation-duration) cubic-bezier(.55,.2,0,1) var(--rotation-delay);
}
.wheel-name {
  transform: rotate(-90deg) translateX(50%);
  margin-bottom: -20px;
}
.wheel-triangle {
  -webkit-clip-path: polygon(50% 0%, 0 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0 100%, 100% 100%);
  width: var(--width);
  @apply h-1/2 origin-top;
}

.shaking {
  animation: shaking 0.15s infinite;
}

.meme {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 999;
}

@keyframes shaking {
 0% { transform: translateX(0) translateY(-5px) }
 25% { transform: translateX(5px) translateY(-5px) }
 50% { transform: translateX(-5px) translateY(10px) }
 75% { transform: translateX(5px) translateY(-5px) }
 100% { transform: translateX(0) translateY(10px) }
}